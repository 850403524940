import React from 'react';

function WorkIcon() {
  return (
    <svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 68.3 60"
      xmlSpace="preserve"
      width={68}
      height={60}
      fill="#419d3e"
    >
      <g>
        <path
          d="M67.8,31.3c-0.7-1.3-1.4-2.6-2.2-3.8c-0.5-0.9-1.2-1.1-1.9-0.7c-0.7,0.4-0.8,1.2-0.4,2c0.7,1.2,1.4,2.4,2.1,3.6
		c0.3,0.5,0.2,0.9-0.3,1.2c-1.1,0.6-2.1,1.2-3.2,1.8c-0.5,0.3-0.9,0.2-1.2-0.4c-3.3-5.9-6.7-11.9-10.1-17.8
		c-0.3-0.5-0.2-0.9,0.3-1.2c1.1-0.6,2.1-1.2,3.1-1.7c0.6-0.3,0.9-0.3,1.2,0.3c0.6,1.1,1.2,2.2,1.9,3.3c0.5,0.8,1.2,1,1.9,0.6
		c0.7-0.4,0.9-1.2,0.4-2c-0.6-1.2-1.3-2.3-2-3.5c-1-1.7-2.8-2.2-4.5-1.3c-1.2,0.6-2.4,1.3-3.6,2c-0.8,0.5-1.4,1.2-1.5,2.1
		c-0.1,0.7,0,1.4,0,2.3c-0.7,0.2-1.7,0.5-2.6,0.7c-0.5,0.1-1,0.1-1.5-0.1c-1.5-0.5-3.1-1.1-4.6-1.6c-1.2-0.4-2.5-0.7-3.8-0.6
		c-2.2,0.2-4.4,0.4-6.6,0.6c-1.8,0.2-3.5,0.5-5,1.7c-0.1,0.1-0.3,0.1-0.4,0.1c-1.1-0.3-2.1-0.6-3.2-0.9c0.7-2.2,0.1-3.5-1.8-4.6
		c-0.9-0.5-1.9-1-2.8-1.5c-2.1-1.2-3.9-0.7-5.1,1.4c-2.1,3.8-4.3,7.5-6.4,11.3c-1.4,2.5-2.7,5-4.1,7.5c0,0.4,0,0.9,0,1.3
		c0.3,0.8,0.7,1.6,1.5,2.1c1.3,0.7,2.6,1.5,3.9,2.2c1.5,0.8,3.3,0.2,4.3-1.2c0.2-0.2,0.3-0.5,0.5-0.9c0.7,0.5,1.3,1,1.9,1.4
		c0.4,0.3,0.4,0.6,0.1,1c-1.7,2.5-0.3,5.9,2.6,6.7c0.2,0.1,0.4,0.1,0.6,0.2c0.1,2.8,1.1,4,4,4.9c-0.1,1.2,0.2,2.3,1,3.2
		c0.8,0.9,1.8,1.4,2.9,1.7c0.1,0.6,0.1,1.1,0.2,1.6c0.8,3.1,4.4,4.4,7,2.5c1.2-0.9,2.4-1.9,3.6-2.9c1.4,1.2,2.8,2.5,4.9,2.2
		c2.1-0.3,3.3-1.6,3.9-3.5c1.6,0.4,3,0.2,4.2-0.8c1.2-1,1.7-2.4,1.7-3.9c2.8-0.8,3.7-1.9,4-4.9c0,0,0,0,0,0c3.5-0.6,4.9-4.6,3-7.1
		c-0.1-0.1-0.1-0.4,0-0.5c0.6-0.7,1.4-1.3,2.3-1.6c0.1,0.1,0.1,0.2,0.2,0.3c1.2,2,3,2.5,5,1.4c1.1-0.6,2.1-1.1,3.1-1.7
		C68.2,35,68.8,33.1,67.8,31.3z M7.6,35.1c-0.1,0.2-0.4,0.4-0.6,0.6c-0.3-0.1-0.5-0.2-0.6-0.3c-1-0.6-2.1-1.1-3.1-1.7
		c-0.7-0.4-0.7-0.6-0.4-1.3c3.3-5.9,6.7-11.8,10-17.7c0.4-0.7,0.6-0.8,1.3-0.4c1,0.6,2.1,1.1,3.1,1.7c0.5,0.3,0.6,0.7,0.3,1.2
		C14.3,23.2,11,29.2,7.6,35.1z M16.2,42.6c-1,0-1.5-0.4-1.8-1.1c-0.3-0.7-0.2-1.5,0.4-2c1.8-1.5,3.5-2.9,5.3-4.3
		c0.7-0.5,1.8-0.3,2.3,0.4c0.6,0.7,0.6,1.8-0.1,2.4c-1.7,1.4-3.5,2.8-5.3,4.2C16.8,42.5,16.4,42.5,16.2,42.6z M20.1,47.6
		c-0.8-0.1-1.5-0.6-1.8-1.3c-0.3-0.7-0.1-1.5,0.6-2c1.1-0.9,2.2-1.8,3.3-2.6c1.2-1,2.5-2,3.7-3c1-0.8,2-0.7,2.7,0.1
		c0.7,0.8,0.5,1.9-0.5,2.7c-2.3,1.8-4.5,3.6-6.8,5.4C21,47.2,20.5,47.4,20.1,47.6z M22.5,51.7c-0.6-0.8-0.5-1.9,0.2-2.5
		c1.7-1.4,3.4-2.7,5.1-4.1c0.6-0.5,1.3-0.5,1.9-0.1c0.6,0.3,0.9,0.9,0.9,1.9c-0.1,0.2-0.3,0.7-0.6,1c-1.7,1.4-3.4,2.8-5.1,4.1
		C24.2,52.7,23.1,52.5,22.5,51.7z M32,54.5c-1,0.9-2.1,1.7-3.2,2.6c-0.2,0.2-0.6,0.2-0.8,0.3c-0.9,0-1.4-0.4-1.8-1.1
		c-0.3-0.7-0.2-1.5,0.3-2c1-0.9,2.1-1.8,3.2-2.6c0.7-0.5,1.8-0.3,2.3,0.4C32.6,52.9,32.6,53.9,32,54.5z M53.1,42.3
		c-0.7,0.4-1.4,0.3-2.1-0.2c-1.1-0.9-2.2-1.8-3.3-2.7c-0.2-0.2-0.5-0.4-0.8-0.4c-0.6-0.1-1.2,0.2-1.4,0.7c-0.3,0.6-0.2,1.2,0.4,1.6
		c1,0.9,2.1,1.7,3.2,2.5c0.1,0.1,0.3,0.2,0.4,0.3c0.8,0.7,0.9,1.8,0.2,2.5c-0.6,0.8-1.7,0.9-2.5,0.3c-1.1-0.8-2.1-1.7-3.2-2.5
		c-0.2-0.2-0.4-0.4-0.7-0.5c-0.7-0.5-1.4-0.4-1.9,0.2c-0.5,0.6-0.4,1.4,0.2,1.9c1.2,1,2.5,1.9,3.7,3c0.4,0.3,0.7,0.8,0.8,1.3
		c0.2,0.7-0.2,1.3-0.8,1.7c-0.7,0.4-1.4,0.4-2.1-0.1c-1.2-0.9-2.3-1.9-3.5-2.8c-0.1-0.1-0.3-0.2-0.4-0.3c-0.6-0.4-1.4-0.3-1.8,0.2
		c-0.5,0.6-0.4,1.3,0.2,1.9c0.6,0.5,1.3,1,1.8,1.5c0.3,0.3,0.6,0.8,0.7,1.3c0.2,0.7-0.2,1.3-0.8,1.7c-0.6,0.4-1.3,0.4-1.9,0
		c-0.7-0.5-1.4-1.1-2.1-1.7c-0.1-0.1-0.2-0.3-0.2-0.4c0-1.9-0.9-3.3-2.5-4.2c1.4-2.9,0.9-4.9-1.7-6.7c1-1.8,1.2-3.7-0.2-5.4
		c-1.3-1.7-3.1-2-5.2-1.4c-0.1-0.2-0.1-0.3-0.2-0.4c-1.1-3-4.5-4-7-2.1c-1.2,0.9-2.4,1.9-3.5,2.7c-1.1-0.9-2.1-1.7-3.2-2.5
		c2.4-4.2,4.8-8.5,7.3-12.8c1.2,0.4,2.4,0.7,3.6,1.1c0.1,0,0.3,0.2,0.4,0.4c0.7,3.8,3.9,5.6,7.7,5.3c0.8-0.1,1.7-0.2,2.5-0.4
		c2.1-0.4,4.1,0.1,5.9,1.3c2.3,1.7,4.7,3.3,6.9,5.1c2.5,1.9,4.9,3.8,7.2,5.8c0.4,0.3,0.7,0.8,0.9,1.3C54.2,41.2,53.8,42,53.1,42.3z
		 M53.5,36c-1.7-1.4-3.5-2.8-5.3-4.2c-2.3-1.8-4.7-3.5-7-5.2c-2.9-2.2-6.1-2.9-9.7-2.1c-1.2,0.3-2.3,0.3-3.5-0.1
		c-1.3-0.4-2.1-1.3-2.5-2.6c-0.2-0.6,0-1,0.6-1.2c0.9-0.3,1.8-0.5,2.7-0.6c2.3-0.3,4.6-0.5,6.9-0.6c0.8,0,1.5,0.1,2.3,0.4
		c1.6,0.5,3.3,1.1,4.9,1.7c1,0.4,2.1,0.4,3.1,0.1c1.1-0.3,2.3-0.6,3.5-1c2.4,4.3,4.9,8.6,7.2,12.8C55.6,34.4,54.6,35.2,53.5,36z"
        />
        <path
          d="M34.1,9.5c0.8,0,1.3-0.6,1.4-1.5c0-1.1,0-2.2,0-3.3c0-1.1,0-2.2,0-3.3c0-0.9-0.6-1.4-1.3-1.4c-0.8,0-1.3,0.6-1.3,1.4
		c0,2.2,0,4.4,0,6.5C32.8,8.8,33.4,9.4,34.1,9.5z"
        />
        <path
          d="M22.3,8.8c0.6,0.9,1.2,1.9,1.8,2.8c0.4,0.6,1,0.8,1.6,0.6c0.6-0.2,0.9-0.7,0.9-1.4c-0.1-0.1-0.1-0.4-0.3-0.7
		c-1.1-1.8-2.2-3.6-3.4-5.4c-0.5-0.8-1.3-1-1.9-0.6c-0.7,0.4-0.8,1.2-0.3,2C21.2,7.1,21.8,7.9,22.3,8.8z"
        />
        <path
          d="M42.4,12.1c0.7,0.4,1.4,0.2,1.9-0.6c1.1-1.8,2.2-3.5,3.3-5.3c0.2-0.3,0.2-0.6,0.3-0.7c0-0.8-0.3-1.2-0.9-1.4
		c-0.6-0.2-1.2,0-1.6,0.6c-1.1,1.8-2.3,3.6-3.4,5.4C41.5,10.9,41.7,11.7,42.4,12.1z"
        />
        <path d="M61.4,24.1c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C60.1,23.5,60.7,24.1,61.4,24.1z" />
      </g>
    </svg>
  );
}

export default WorkIcon;
